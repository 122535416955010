import React from 'react';
import Card from './Cards';
import styles from './styles.module.css';

const flRefText = `身近にフリーランスのコンサルタント様はいらっしゃいませんか？
ProConnectではハイスキルなコンサルタントの方を随時募集しております。
ProConnectを通じてご紹介頂いた方が登録→ご成約頂いた場合、ご紹介者さまに最大60万円の謝礼金をご用意しております。
ぜひ、お知り合いの方をご紹介ください！`;
const clRefText = `最適な人材がおらずお困りのクライアント様はいらっしゃいませんか？
ProConnectではフリーランスのコンサルタントやエンジニアへご紹介させていただく案件を増やすため、お付き合いをさせていただくクライアント様を募集しております。
弊社人材にてご成約決定後、最大60万円の謝礼金をご用意しております。`;

const Introduce: React.FC = () => {
    return (
        <section className="w-full md:min-w-[1000px]">
            <div
                className="md:bg-no-repeat md:bg-cover md:w-full md:py-[80px]"
                id={styles.bg}
            >
                <div
                    className="justify-center items-center flex m-auto flex-col bg-cover bg-center w-full md:w-[1000px]"
                    id={styles.titleSpBg}
                >
                    <div
                        className="h-10 w-[330px] flex justify-center items-center mx-auto md:h-14 md:w-[800px] md:mb-10"
                        id={styles.caption}
                    >
                        <h2
                            className="md:w-[424px] md:h-[46px] text-[18px] leading-[26px] font-bold md:text-[32px] md:leading-[46px] tracking-normal"
                            id={styles.innerCaption}
                        >
                            謝礼金最大60万円プレゼント
                        </h2>
                    </div>
                </div>

                <div
                    className="justify-center items-center flex m-auto flex-col md:w-[1000px]"
                    id={styles.contentsSpBg}
                >
                    <div className="md:flex">
                        <div className="p-0 my-[16px] md:mr-[39px] md:my-0">
                            <Card title="フリーランス紹介">{flRefText}</Card>
                        </div>
                        <div className="mb-[40px] md:mb-0">
                            <Card title="クライアント紹介">{clRefText}</Card>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Introduce;
