import { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const imagePCFv01 =
    '/images/ProConnect_design231222/PC/image_fv01/image_fv01_2x.webp';
// const imagePCFv02 =
//     '/images/ProConnect_design231222/PC/image_fv02/image_fv02_2x.webp';
// const imagePCFv03 =
//     '/images/ProConnect_design231222/PC/image_fv03/image_fv03_2x.webp';
// const imagePCFv04 =
//     '/images/ProConnect_design231222/PC/image_fv04/image_fv04_2x.webp';
// const imagePCFv05 =
//     '/images/ProConnect_design231222/PC/image_fv05/image_fv05_2x.webp';

const pcImages = [
    imagePCFv01,
    // imagePCFv02,
    // imagePCFv03,
    // imagePCFv04,
    // imagePCFv05
];

const imageSPFv01 =
    '/images/ProConnect_design231222/SP/image_sp_fv01/property_1_sp_fv_image01_2x.webp';
// const imageSPFv02 =
//     '/images/ProConnect_design231222/SP/image_sp_fv02/property_1_sp_fv_image02_2x.webp';
// const imageSPFv03 =
//     '/images/ProConnect_design231222/SP/image_sp_fv03/property_1_sp_fv_image03_2x.webp';
// const imageSPFv04 =
//     '/images/ProConnect_design231222/SP/image_sp_fv04/property_1_sp_fv_image04_2x.webp';
// const imageSPFv05 =
//     '/images/ProConnect_design231222/SP/image_sp_fv05/property_1_sp_fv_image05_2x.webp';

const spImages = [
    imageSPFv01,
    // imageSPFv02,
    // imageSPFv03,
    // imageSPFv04,
    // imageSPFv05
];

function FirstView() {
    const [index, setIndex] = useState(0);
    const [isZoomGreaterThan100, setIsZoomGreaterThan100] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % pcImages.length);
        }, 6000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const checkZoomLevel = () => {
            const zoomLevel = window.innerWidth / window.outerWidth;
            setIsZoomGreaterThan100(zoomLevel > 1);
        };

        // 初回実行
        checkZoomLevel();

        // リサイズ時にも検出
        window.addEventListener('resize', checkZoomLevel);

        // クリーンアップ
        return () => {
            window.removeEventListener('resize', checkZoomLevel);
        };
    }, []);

    return (
        <div className="bg-[#00378E] flex flex-col-reverse md:flex-row">
            <div
                className={`flex-1 md:max-w-[1920px] flex flex-col-reverse md:flex-row md:mx-auto md:items-center`}
            >
                <div
                    className={`flex-1 md:max-w-[100%] py-[20px] md:py-[53px] px-[16px] md:pr-0 xl:px-0 md:ml-[30px] ${
                        !isZoomGreaterThan100 ? 'md:ml-[30px]' : ''
                    }`}
                >
                    {/* <p className="font-hiragino-sans text-[#FFEC5D] text-base md:text-[22px] lg:text-[28px] not-italic font-normal leading-[normal] mb-[18px] md:mb-[24px]"> */}
                        {/* 仕事も、プライベートも、本気で。 */}
                    {/* </p> */}
                    <p className="font-hiragino-sans text-[#FFEC5D] text-[22px] md:text-[28px] lg:text-[38px] font-medium leading-[120%] mb-[12px] md:mb-[20px]">
                    ハイクラス案件をあなたに
                    </p>
                    <p className="font-hiragino-sans text-[#FFF] text-[22px] md:text-[28px] lg:text-[38px] font-medium leading-[120%] mb-[12px] md:mb-[16px]">
                        {/* 理想の働き方を実現する */}
                    </p>
                    <p className="font-hiragino-sans text-[#FFF] text-[31px] md:text-[38px] lg:text-[56px] font-bold leading-[120%] mb-[20px] md:mb-[40px]">
                        フリーコンサルのための
                        <br className="hidden md:block" />
                        エージェント型
                        <br className="block md:hidden" />
                        案件紹介サービス
                    </p>
                    <div className="flex justify-between md:justify-normal gap-x-[8px] md:gap-x-[12px] lg:gap-x-[20px]">
                        <div className="bg-white pt-[12px] pb-[8px] md:py-[20px] px-[6px] md:px-0 lg:px-[16px] xl:px-[28px] rounded-[10px] md:min-w-[160px] xl:min-w-[258px] max-h-[70px] md:max-h-full whitespace-nowrap flex-1">
                            <p className="font-hiragino-sans text-[#000032] text-center text-[12px] md:text-[20px] not-italic font-semibold leading-[100%] mb-[8px] md:mb-[16px]">
                                大募集中
                            </p>
                            <p className="font-hiragino-sans text-[#000032] text-center text-[10px] md:text-[15px] lg:text-[20px] not-italic font-semibold leading-[100%] whitespace-nowrap">
                                <span className="font-avenir-next text-[#1E8FF8] text-center text-[25px] md:text-[30px] lg:text-[35px] xl:text-[60px] not-italic font-semibold leading-[100%] tracking-[-2.1px] md:tracking-[-3.5px]">
                                    P
                                </span>
                                <span className="font-avenir-next text-[#1E8FF8] text-center text-[25px] md:text-[30px] lg:text-[35px] xl:text-[60px] not-italic font-semibold leading-[100%] tracking-[-1.8px] md:tracking-[-3.5px]">
                                    M
                                </span>
                                <span className="font-avenir-next text-[#1E8FF8] text-center text-[25px] md:text-[30px] lg:text-[35px] xl:text-[60px] not-italic font-semibold leading-[100%] tracking-[-0.3px] md:tracking-[-0.7px]">
                                    O
                                </span>
                            </p>
                        </div>
                        <div className="bg-white pt-[12px] pb-[8px] md:py-[20px] px-[6px] md:px-0 lg:px-[16px] xl:px-[28px] rounded-[10px] md:min-w-[160px] xl:min-w-[258px] max-h-[70px] md:max-h-full whitespace-nowrap flex-1">
                            <p className="font-hiragino-sans text-[#000032] text-center text-[12px] md:text-[20px] not-italic font-semibold leading-[100%] mb-[8px] md:mb-[16px]">
                                平均単価
                            </p>
                            <p className="font-hiragino-sans text-[#000032] text-center text-[15px] md:text-[28px] lg:text-[34px] not-italic font-semibold leading-[100%] whitespace-nowrap">
                                <span className="font-avenir-next text-[#1E8FF8] text-center text-[30px] md:text-[42px] lg:text-[58px] xl:text-[70px] not-italic font-semibold leading-[100%] tracking-[-5.1px] md:tracking-[-11.9px]">
                                    1
                                </span>
                                <span className="font-avenir-next text-[#1E8FF8] text-center text-[30px] md:text-[42px] lg:text-[58px] xl:text-[70px] not-italic font-semibold leading-[100%] tracking-[-1.8px] md:tracking-[-4.9px]">
                                    5
                                </span>
                                <span className="font-avenir-next text-[#1E8FF8] text-center text-[30px] md:text-[42px] lg:text-[58px] xl:text-[70px] not-italic font-semibold leading-[100%] tracking-[-0.3px] md:tracking-[-0.7px]">
                                    0
                                </span>
                                万円
                            </p>
                        </div>
                        <div className="bg-white pt-[12px] pb-[8px] md:py-[20px] px-[6px] md:px-0 lg:px-[16px] xl:px-[28px] rounded-[10px] md:min-w-[160px] xl:min-w-[258px] max-h-[70px] md:max-h-full flex-1">
                            <p className="font-hiragino-sans text-[#000032] text-center text-[12px] md:text-[20px] not-italic font-semibold leading-[100%] mb-[8px] md:mb-[16px]">
                                スピード参画
                            </p>
                            <p className="font-hiragino-sans text-[#000032] text-center text-[15px] md:text-[28px] lg:text-[34px] not-italic font-semibold leading-[100%]">
                                最短
                                <span className="text-[#1E8FF8] text-center text-[30px] md:text-[42px] lg:text-[58px] xl:text-[70px] not-italic font-semibold leading-[100%] md:tracking-[-2.1px]">
                                    1
                                </span>
                                日
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative md:max-h-[500px] lg:max-h-full my-auto md:ml-[30px] mb-[0px]">
                    {/* PC */}
                    <div className="hidden md:block">
                        <img
                            src="/images/ProConnect_design231222/PC/image_fv_symbol mark.svg"
                            alt="Symbol Mark"
                            className="w-[487px]"
                        />
                        <TransitionGroup>
                            <CSSTransition
                                key={pcImages[index]}
                                timeout={1200}
                                classNames={{
                                    enter: styles.fadeEnter,
                                    enterActive: styles.fadeEnterActive,
                                    exit: styles.fadeExit,
                                    exitActive: styles.fadeExitActive
                                }}
                            >
                                <img
                                    src={pcImages[index]}
                                    alt=""
                                    className="absolute bottom-0 left-0 w-[487px]"
                                />
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                    {/* SP */}
                    {/* <div className="block md:hidden max-w-[487px]">
                        <div style={{ width: '100%', height: 300 }}>
                            <TransitionGroup>
                                <CSSTransition
                                    key={spImages[index]}
                                    timeout={1200}
                                    classNames={{
                                        enter: styles.fadeEnter,
                                        enterActive: styles.fadeEnterActive,
                                        exit: styles.fadeExit,
                                        exitActive: styles.fadeExitActive
                                    }}
                                >
                                    <img
                                        src={spImages[index]}
                                        alt=""
                                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[487px]"
                                        style={{
                                            height: '90%',
                                            width: 'auto',
                                            marginTop: 10
                                        }}
                                    />
                                </CSSTransition>
                            </TransitionGroup>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default FirstView;
