import React from 'react';
import styles from './styles.module.css';
import { useMedia } from '../../../hooks/useMedia';

type Props = {
    category: string[];
    publishedAt: string;
    title: string;
    link: string;
};

const NewsCard: React.VFC<Props> = ({ category, publishedAt, title, link }) => {
    const isPC = useMedia();

    return isPC ? (
        <div className="w-[720px] h-[74px] flex border-b-2 border-lightGrey py-5">
            <p className="min-w-[100px] text-center border rounded border-primary px-2 py-1 ml-4 text-xs leading-5">
                {category}
            </p>
            <p className={`${styles.date} leading-8 pl-6`}>{publishedAt}</p>

            <a
                className={`${styles.link} max-w-[450px] pl-10 mr-3 leading-8 overflow-hidden overflow-ellipsis`}
                href={link}
            >
                {title}
            </a>

            <div className="ml-auto">
                <a href={link}>
                    <div className={styles.key}></div>
                </a>
            </div>
        </div>
    ) : (
        <div className="flex min-h-[105px] border-b-2 border-lightGrey py-5 items-center">
            <div className="md:flex-row">
                <div className="flex mb-2">
                    <p className="min-w-[100px] text-center border rounded border-primary px-2 py-1 ml-4 text-xs leading-5">
                        {category}
                    </p>
                    <p className={`${styles.date} leading-8 pl-4`}>
                        {publishedAt}
                    </p>
                </div>
                <a className={styles.link} href={link}>
                    <p className="break-all pl-4 leading-8 overflow-hidden">
                        {title}
                    </p>
                </a>
            </div>
            <div className="ml-auto">
                <a href={link}>
                    <div className={styles.key}></div>
                </a>
            </div>
        </div>
    );
};

export default NewsCard;
