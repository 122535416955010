import NewsCard from './NewsCard/index';
import { NewsDataProps } from '../../types';
import { useUrlContext } from '../../provider/urlContext';

const NewsList = ({
    newsContentList
}: {
    newsContentList: NewsDataProps[];
}) => {
    const { query } = useUrlContext();
    return (
        <section id="news" className="w-full md:min-w-[1000px]">
            <div className="md:flex mx-auto md:py-30 py-10 justify-center bg-white w-full">
                <div>
                    <h2
                        className={`font-noto-serif font-bold md:text-4xl text-lg text-center whitespace-nowrap`}
                    >
                        ニュース
                    </h2>
                    <div className="bg-primary mx-auto my-6 md:h-1 mt-3 h-[2px] md:w-24 w-10"></div>
                </div>

                <div className="md:ml-20 lg:ml-42 border-t-2 border-lightGrey">
                    {newsContentList.map((news: NewsDataProps, id: number) => (
                        <NewsCard
                            key={id}
                            category={news.category}
                            publishedAt={news.publishedAt}
                            title={news.title}
                            link={`news/${query}#${id}`}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default NewsList;
