import axiosInstance, { AxiosRequestConfig } from '../config/axios';

export const rootPrefixPath = '/';
export const lpPrefixPath = rootPrefixPath + 'lp';

export const projectPrefixPath = lpPrefixPath + '/projects';
export const columnPrefixPath = lpPrefixPath + '/columns';
export const contactPrefixPath = lpPrefixPath + '/contacts';

export const searchProjectPrefixPath = projectPrefixPath + '/search';
export const getSuperUpstreamExcitingProjectPrefixPath =
    projectPrefixPath + '/superUpstreamExciting';
export const createContactPrefixPath = contactPrefixPath + '/new';
export const columnOgpPrefixPath = columnPrefixPath + '/ogp-info';
export const mastersPrefixPath = 'masters';


export default class Api {
    static async get<Response>(url: string): Promise<Response> {
        const res = await axiosInstance.get<AxiosRequestConfig, Response>(url);
        return res;
    }

    static async post<Request, Response>(url: string, req: Request) {
        const res = await axiosInstance.post<AxiosRequestConfig, Response>(
            url,
            req
        );
        return res;
    }
}
