import React from 'react';
import Logo from '../../../public/images/pro-connect-white.svg';
import styles from './styles.module.css';

type Props = {
    children: React.ReactNode;
};

const sectionsWrap: React.FC<Props> = ({ children }) => {
    return (
        <section
            id="appeal"
            className="w-full md:min-w-[1000px] bg-primary py-[40px] md:py-[120px] px-auto"
        >
            <div className="flex h-[34px] mb-[40px] mx-auto justify-center items-center md:h-[71px] md:mb-[76px] md:min-w-[1000px]">
                <img
                    src={Logo.src}
                    alt="logo"
                    loading="lazy"
                    className="w-[135PX] md:w-[257px]"
                />
                <h1
                    className="flex justify-center  text-white font-bold text-[18px] ml-[4px] items-center leading-[1em] md:text-[32px] md:ml-[10px]"
                    id={styles.title}
                >
                    だけの魅力
                </h1>
            </div>
            <div>{children}</div>
        </section>
    );
};

export default sectionsWrap;
