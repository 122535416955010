import { Swiper, SwiperSlide } from 'swiper/react';
import { useRouter } from 'next/router';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import { useEffect, useRef, useState, useCallback } from 'react';
import ProjectCard from '@/components/Project/ProjectCards';
import { ProjectProps } from '@/types';
import styles from './styles.module.css';
import { useUrlContext } from '@/provider/urlContext';
import Link from 'next/link';

const Project = ({ projects }: { projects: ProjectProps[] }) => {
    const prevRef = useRef<HTMLDivElement>(null);
    const nextRef = useRef<HTMLDivElement>(null);
    const router = useRouter();
    const { signUpUrl, signInUrl, query } = useUrlContext();
    const domain = process.env.NEXT_PUBLIC_PRO_CONNECT_APP_FL_DOMAIN;

    const [isClient, setIsClient] = useState(false);
    useEffect(() => setIsClient(true), []);

    const onClickSignUp = useCallback(
        (id: String) => {
            const signUpUrlWithDomain = `https://${domain}${signUpUrl}`;
            router.push(signUpUrlWithDomain);
        },
        [domain, signUpUrl, router]
    );

    const onClickSignIn = useCallback(
        (id: String) => {
            const signUpUrlWithDomain = `https://${domain}${signInUrl}`;
            router.push(signUpUrlWithDomain);
        },
        [domain, signInUrl, router]
    );

    return (
        <>
            <section className="text-2xl w-full md:min-w-[1000px]" id="project">
                <h2
                    className={`${styles.title} text-4xl text-center mt-20 font-bold leading-normal`}
                >
                    戦略・業務・IT領域で
                    <br />
                    毎月300件以上の新規案件を取り扱い
                </h2>
                <div
                    className={`${styles.bar} bg-primary mx-auto mt-[16px] mb-6 h-1 w-24`}
                ></div>
                <h3
                    className={`${styles.subTitle} mx-auto my-10 text-center font-medium`}
                >
                    面談後、即日案件紹介します
                </h3>
                <div
                    className={`${styles.projectList} w-300 mx-auto mt-0 mb-20 h-[275px] relative`}
                >
                    <div ref={prevRef} className={styles.prev}></div>
                    <div ref={nextRef} className={styles.next}></div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={5}
                        slidesPerGroup={1}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        modules={[Navigation]}
                        className={styles.swiperBox}
                        onInit={(swiper: any) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        breakpoints={{
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30
                            },
                            1150: {
                                slidesPerView: 3,
                                spaceBetween: 20
                            }
                        }}
                    >
                        {isClient &&
                            projects.map((d, id) => {
                                return (
                                    <SwiperSlide key={id}>
                                        <ProjectCard
                                            project={d}
                                            onClickSignUp={onClickSignUp}
                                            onClickSignIn={onClickSignIn}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                </div>
                <Link href={`/project${query}`}>
                    <div
                        className={`${styles.bottomLink} text-white bg-[#1D55AE] max-w-[526px] mt-0 mx-auto mb-[62px] px-auto py-4 cursor-pointer text-[16px] md:text-[20px] font-medium text-center rounded-[60px] relative block`}
                    >
                        すべての案件を見る
                        <div className={styles.key3}></div>
                    </div>
                </Link>
            </section>
        </>
    );
};

export default Project;
